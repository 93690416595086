@font-face {
    font-family: 'NunitoSans-Regular';
    src: url('../../Resources/Fonts/NunitoSans_10pt-Regular.ttf') format('woff2');
}

@font-face {
    font-family: 'NunitoSans-Bold';
    src: url('../../Resources/Fonts/NunitoSans_10pt-Bold.ttf') format('woff2');
}

@font-face {
    font-family: 'NunitoSans-Light';
    src: url('../../Resources/Fonts/NunitoSans_10pt-Light.ttf') format('woff2');
}


.NewWork-main {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

}

.animation-holder{
    width: 100%;
    height: 100vh;
}

.first-trigger-row {
    display: flex;
    width: 100%;

}

.second-trigger-row {
    display: flex;
    width: 100%;
    height: 40vh;

}

.work-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    position: relative;

}

.work-void1 {
    width: 25%;
    border-right: 1px solid #1F1F1F;

}

.work-void2 {
    width: 25%;
    border-left: 1px solid #1F1F1F;

}

.work-void3 {
    width: 25%;
}

.work-void4 {
    width: 25%;
}

.work-paragraph {
    width: 50%;
    border-top: 1px solid #1F1F1F;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.work-paragraph::before {
    width: 1px;
    top: 0;
    left: 50%;
    height: 100%;
    content: "\a";
    position: absolute;
    background-color: #1F1F1F;
    z-index: 0;
}

.video-div1 {
    width: 90%;
    height: 90%;
    margin: 0 auto;
    z-index: 1;
}

.video-div2 {
    width: 80%;
    height: 80%;
    z-index: 1;
    position: absolute;
    left: 100%;
}

.work-trigger::before {
    width: 1px;
    top: 0;
    left: 50%;
    height: 100%;
    content: "\a";
    position: absolute;
    background-color: #1F1F1F;
    z-index: 0;
}

.my-work-header {
    font-family: 'NunitoSans-Bold';
    font-size: clamp(2rem, 1.9333rem + 0.3556vw, 2.5rem);
    z-index: 10;
    align-self: flex-start;
    color: white;
    position: relative;

}

.my-work-paragraph {
    font-family: 'NunitoSans-Bold';
    color: gray;
    text-align: justify;
    font-size: clamp(0.75rem, 0.7167rem + 0.1778vw, 1rem);
    z-index: 10;
    position: relative;
}

.work-paragraph-container {
    width: 70%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@media (max-width: 1000px) {
    .work-paragraph-container {
        width: 90%;
    }
}

.work-void3 {
    width: 25%;
    border-right: 1px solid #1F1F1F;
    border-top: 1px solid #1F1F1F;
    background-color:  rgb(255, 241, 165);
    cursor: pointer;
    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.work-void3:hover{
    background-color: rgb(9, 8, 13);
    border: 1px solid #1F1F1F;
    box-shadow: inset 0 0 100px rgba(255, 241, 165,.5), 0 0 100px rgba(255, 241, 165,.2);
    outline-color: #1F1F1F;
    outline-offset: 15px;
    text-shadow: 1px 1px 2px #427388; 
    color: white;
}

.work-void4 {
    width: 25%;
    border-left: 1px solid #1F1F1F;
    border-top: 1px solid #1F1F1F;

}

.void3-text{
    font-family: 'NunitoSans-Bold';
    font-size: clamp(1.5rem, 1.3667rem + 0.7111vw, 2.5rem);
}


[data-scroll] {
    transition: opacity 1s;
  }
  [data-scroll="in"] {
    opacity: 1;
  }
  [data-scroll="out"] {
    opacity: 0;
  }
