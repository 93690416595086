@font-face {
    font-family: 'NunitoSans-Regular';
    src: url('../../../Resources/Fonts/NunitoSans_10pt-Regular.ttf') format('woff2');
}

@font-face {
    font-family: 'NunitoSans-Bold';
    src: url('../../../Resources/Fonts/NunitoSans_10pt-Bold.ttf') format('woff2');
}

@font-face {
    font-family: 'NunitoSans-Light';
    src: url('../../../Resources/Fonts/NunitoSans_10pt-Light.ttf') format('woff2');
}

.footer-main{
    padding: 75px;
    background-color: black;
    padding-left: 100px;
    display: flex;
    z-index: 1;
    position: relative;
}

.contact-section{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.contact-header{
    font-family: 'NunitoSans-Bold';
    color: white;
    font-size: clamp(0.625rem, 0.575rem + 0.2667vw, 1rem);
}

.contact-info{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.my-email{
    margin-left: 20px;
    font-family: 'NunitoSans-Light';
    color: white;
    font-size: clamp(0.625rem, 0.575rem + 0.2667vw, 1rem);
}

.socials-section{
    margin-left: 200px;
}

.social-holder{
    width: 20px;
    height: 20px;
    margin-right: 20px;
    cursor: pointer;
    margin-left: 0px;
    margin-top: 0px;
    
}

.sicials-row{
    display: flex;
}

a{
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.final-position{
    display: flex;
}

.contact-holder{
    margin: 0px;
}

@media (max-width: 1000px) {
    .footer-main{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 0px;
    }

    .socials-section{
        margin-left: 0px;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .final-position{
        display: flex;
        flex-direction: column;
    }
}