.portfolio-main {
    width: 100%;
    z-index: 1;
}


.fade-overflow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    /* Set the initial opacity to 0 */
    opacity: 1;
    pointer-events: none;
    /* Prevent interaction with this element */
    z-index: 2;
    /* Ensure it's above the content */
    /* Add any transitions you want */
    animation: fade-in 1s ease forwards;
}

@keyframes fade-in {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fade-overflow2 {
    display: flex;
    position: fixed;
    z-index: 10;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    margin: 0;
    pointer-events: none;
}

.portfolio-transition-slider {
    transform: scaleY(1);
    background-color: black;
    width: 25%;
    transform-origin: bottom center;
    /* Set the transform origin to top center */
    animation: slideIn3 1s forwards;
    z-index: 9999;

}

.portfolio-transition-slider:nth-child(1) {
    animation: slideIn3 1s forwards;
    animation-delay: 0.2s;
    /* Adjust the delay as needed */
}

.portfolio-transition-slider:nth-child(2) {
    animation: slideIn3 1s forwards;
    animation-delay: 0.4s;
    /* Adjust the delay as needed */
}

.portfolio-transition-slider:nth-child(3) {
    animation: slideIn3 1s forwards;
    animation-delay: 0.6s;
    /* Adjust the delay as needed */
}

.portfolio-transition-slider:nth-child(4) {
    animation: slideIn3 1s forwards;
    animation-delay: 0.8s;
    /* Adjust the delay as needed */
}

@keyframes slideIn3 {
    from {
        transform: scaleY(1);
    }

    to {
        transform: scaleY(0);
    }
}

.button-clicked{
    transform: scaleY(0);
}

.portfolio-transition-slider.button-clicked:nth-child(1) {
    animation: slideIn2 1s forwards;
    animation-delay: 0.2s;

}

.portfolio-transition-slider.button-clicked:nth-child(2) {
    animation: slideIn2 1s forwards;
    animation-delay: 0.4s;

}

.portfolio-transition-slider.button-clicked:nth-child(3) {
    animation: slideIn2 1s forwards;
    animation-delay: 0.6s;

}

.portfolio-transition-slider.button-clicked:nth-child(4) {
    animation: slideIn2 1s forwards;
    animation-delay: 0.8s;

}

.my-index{
    z-index: 1;
}