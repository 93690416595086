@font-face {
    font-family: 'NunitoSans-Regular';
    src: url('../../Resources/Fonts/NunitoSans_10pt-Regular.ttf') format('woff2');
}

@font-face {
    font-family: 'NunitoSans-Bold';
    src: url('../../Resources/Fonts/NunitoSans_10pt-Bold.ttf') format('woff2');
}

@font-face {
    font-family: 'NunitoSans-Light';
    src: url('../../Resources/Fonts/NunitoSans_10pt-Light.ttf') format('woff2');
}


.projects-main{
    width: 100%;
}

.fade-projects{
    display: flex;
    position: fixed;
    z-index: 10;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    margin: 0;
    pointer-events: none;
}

.projects-transition-slider{
    transform: scaleY(1);
    background-color: black;
    width: 25%;
    transform-origin: bottom center;
    /* Set the transform origin to top center */
    animation: slideIn3 1s forwards;
    z-index: 9999;
}

.projects-transition-slider:nth-child(1) {
    animation: slideIn3 1s forwards;
    animation-delay: 0.2s;
    /* Adjust the delay as needed */
}

.projects-transition-slider:nth-child(2) {
    animation: slideIn3 1s forwards;
    animation-delay: 0.4s;
    /* Adjust the delay as needed */
}

.projects-transition-slider:nth-child(3) {
    animation: slideIn3 1s forwards;
    animation-delay: 0.6s;
    /* Adjust the delay as needed */
}

.projects-transition-slider:nth-child(4) {
    animation: slideIn3 1s forwards;
    animation-delay: 0.8s;
    /* Adjust the delay as needed */
}

.projects-layout{
    display: flex;
    margin-top: 75px;
}

.projects-left{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 50%;
    height: 70vh;
    border-top: 1px solid #1F1F1F;
    border-right: 1px solid #1F1F1F;
    border-bottom: 1px solid #1F1F1F;


}

.projects-right{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 50%;
    height: 70vh;
    border-top: 1px solid #1F1F1F;
    border-bottom: 1px solid #1F1F1F;

}

.projects-right::before {
    width: 1px;
    top: 0;
    left: 50%;
    height: 100%;
    content: "\a";
    position: absolute;
    background-color: #1F1F1F;
    z-index: 0;

}

.projects-right::after {
    height: 1px;
    top: 50%;
    left: 0;
    width: 100%;
    content: "\a";
    position: absolute;
    background-color: #1F1F1F;
    z-index: 0;

}

.projects-left::before {
    width: 1px;
    top: 0;
    left: 50%;
    height: 100%;
    content: "\a";
    position: absolute;
    background-color: #1F1F1F;
    z-index: 0;

}

.projects-left::after {
    height: 1px;
    top: 50%;
    left: 0;
    width: 100%;
    content: "\a";
    position: absolute;
    background-color: #1F1F1F;
    z-index: 0;

}

.projects-row{
    display: flex;
    width: 100%;
    height: 40vh;
    position: relative;
    border-bottom: 1px solid #1F1F1F;

}

.projects-r-cell{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: gray;
    font-size: clamp(1.5rem, 1.3667rem + 0.7111vw, 2.5rem);
    text-align: center;
    font-family: 'NunitoSans-Bold';
    cursor: pointer;
}

.secondary-row:nth-child(1) .projects-r-cell:nth-child(1){
    border-right: 1px solid #1F1F1F;

}

.video-tittle{
    width: 90%;
}

.secondary-row:nth-child(2)::before {
    width: 1px;
    top: 0;
    left: 50%;
    height: 100%;
    content: "\a";
    position: absolute;
    background-color: #1F1F1F;
    z-index: 0;

}

.secondary-row{
    width: 50%;
    height: 100%;
    position: relative;
    display: flex;
}

.projects-row::before {
    width: 1px;
    top: 0;
    left: 50%;
    height: 100%;
    content: "\a";
    position: absolute;
    background-color: #1F1F1F;
    z-index: 0;

}

.projects-r-cell video {
    /* Your video styles (e.g., width, height) */
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    
    z-index: 1;
    opacity: 0.8; /* Adjust opacity as needed */
    pointer-events: none; /* Allows mouse events to pass through to the cell */
  }

  .cell-image {
    width: 100%;
    height: 100%; /* Make the image fill the height of the parent */
    object-fit: cover; /* Ensures the image covers the parent while maintaining its aspect ratio */
    transition: all 0.3s ease;
}


@media (max-width: 1400px) {
    .secondary-row{
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        
    }

    .projects-row{
        flex-direction: column;
        height: 80vh;
    }

    .secondary-row:nth-child(1) .projects-r-cell{
        border-bottom: 1px solid #1F1F1F;
    
    }
}

@media (max-width: 1000px) {
    .projects-layout{
        flex-direction: column;
    }

    .projects-left{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 50vh;
        border-top: 1px solid #1F1F1F;
        border-right: 1px solid #1F1F1F;
        border-bottom: 1px solid #1F1F1F;
    
    
    }
    
    .projects-right{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 70vh;
        border-top: 1px solid #1F1F1F;
        border-bottom: 1px solid #1F1F1F;
    
    }

    .projects-left{
        
        border-bottom: 0px solid #1F1F1F;
    
    
    }
    
}

@media (max-width: 800px) {
    .secondary-row{
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .projects-r-cell{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    
    }

    .projects-row{
        flex-direction: column;
        height: 160vh;
    }

    .smallerrow{
        height: 80vh !important;
    }

    .projects-row::before {
        width: 0px;
        top: 0;
        left: 50%;
        height: 100%;
        content: "\a";
        position: absolute;
        background-color: #1F1F1F;
        z-index: 0;
    
    }

    .secondary-row:nth-child(2)::before {
        width: 0px;
        top: 0;
        left: 50%;
        height: 100%;
        content: "\a";
        position: absolute;
        background-color: #1F1F1F;
        z-index: 0;
    
    }

    .secondary-row:nth-child(2) .projects-r-cell:nth-child(1){
        border-bottom: 1px solid #1F1F1F;
    
    }

    .secondary-row:nth-child(1) .projects-r-cell:nth-child(1){
        border-right: 0px solid #1F1F1F;
    
    }
}


@media (max-width: 600px) {
    .solar-main{
        transform: scale(0.8);
    }

    .projects-right{
        
        height: 50vh;
        
    
    }

}

@media (max-width: 450px) {
    .solar-main{
        transform: scale(0.6);
    }

    .projects-left{
        
        height: 40vh;
        
    
    }

}

@media (max-width: 350px) {
    .solar-main{
        transform: scale(0.4);
    }

    .projects-right{
        
        height: 40vh;
        
    
    }

    

}