.planet-container {
    border-radius: 50%;
    box-shadow: 5px -3px 10px 3px #5e90f1;
    height: 500px;
    overflow: hidden;
    position: relative;
    width: 500px;
    z-index: 1;
}
.night {
    animation: rotate-night 80s linear infinite;
    background-image: url('../../Resources/Images/2k_earth_nightmap.jpg');
    background-size: 200%;
    height: 500px;
    position: absolute;
    width: 500px;
    z-index: 2;
}
.day {
    animation: rotate-day 80s linear infinite;
    background-image: url('../../Resources/Images/2k_earth_daymap.jpg');
    background-size: 200%;
    border-left: solid 1px black;
    border-radius: 50%;
    box-shadow: 5px 0 20px 10px #040615 inset; 
    height: 500px;
    margin-left: 110px;
    position: absolute;
    width: 500px;
    z-index: 3;
}
.clouds {
    animation: rotate-day 50s linear infinite, spin-clouds 100s ease infinite;
    background-image: url('../../Resources/Images/2k_earth_clouds.jpg');
    background-size: 200%;
    border-radius: 50%;    
    box-shadow: 5px 0 20px 10px #040615 inset, -9px 0px 20px 10px #5e90f1 inset;
    height: 500px;
    margin-left: 100px;
    opacity: 0.45;
    position: absolute;
    width: 500px;
    z-index: 4;
}
.inner-shadow {
    background: transparent;
    border-radius: 50%;
    box-shadow: -5px 0 10px 1px #152b57 inset, 5px 0 10px 1px #040615 inset;
    height: 500px;
    margin-left: 0;
    position: absolute;
    width: 500px;
    z-index: 5;
}

@keyframes rotate-day {
    0% { background-position: 120% 0; }
    100% { background-position: -80% 0; }
}
@keyframes rotate-night {
    0% { background-position: calc(120% + 120px) 0; }
    100% { background-position: calc(-80% + 120px) 0; }
}
@keyframes spin-clouds {
    0% { transform: rotate(0deg); }
    50% { transform: rotate(20deg); }
    100% { transform: rotate(0deg); }
}


@media (max-width: 700px) {
    .planet-container {
        border-radius: 50%;
        box-shadow: 5px -3px 10px 3px #5e90f1;
        height: 400px;
        overflow: hidden;
        position: relative;
        width: 400px;
        z-index: 1;
    }
    .night {
        animation: rotate-night 80s linear infinite;
        background-image: url('../../Resources/Images/2k_earth_nightmap.jpg');
        background-size: 200%;
        height: 400px;
        position: absolute;
        width: 400px;
        z-index: 2;
    }
    .day {
        animation: rotate-day 80s linear infinite;
        background-image: url('../../Resources/Images/2k_earth_daymap.jpg');
        background-size: 200%;
        border-left: solid 1px black;
        border-radius: 50%;
        box-shadow: 5px 0 20px 10px #040615 inset; 
        height: 400px;
        margin-left: 110px;
        position: absolute;
        width: 400px;
        z-index: 3;
    }
    .clouds {
        animation: rotate-day 50s linear infinite, spin-clouds 100s ease infinite;
        background-image: url('../../Resources/Images/2k_earth_clouds.jpg');
        background-size: 200%;
        border-radius: 50%;    
        box-shadow: 5px 0 20px 10px #040615 inset, -9px 0px 20px 10px #5e90f1 inset;
        height: 400px;
        margin-left: 100px;
        opacity: 0.45;
        position: absolute;
        width: 400px;
        z-index: 4;
    }
    .inner-shadow {
        background: transparent;
        border-radius: 50%;
        box-shadow: -5px 0 10px 1px #152b57 inset, 5px 0 10px 1px #040615 inset;
        height: 400px;
        margin-left: 0;
        position: absolute;
        width: 400px;
        z-index: 5;
    }
}


@media (max-width: 500px) {
    .planet-container {
        border-radius: 50%;
        box-shadow: 5px -3px 10px 3px #5e90f1;
        height: 300px;
        overflow: hidden;
        position: relative;
        width: 300px;
        z-index: 1;
    }
    .night {
        animation: rotate-night 80s linear infinite;
        background-image: url('../../Resources/Images/2k_earth_nightmap.jpg');
        background-size: 200%;
        height: 300px;
        position: absolute;
        width: 300px;
        z-index: 2;
    }
    .day {
        animation: rotate-day 80s linear infinite;
        background-image: url('../../Resources/Images/2k_earth_daymap.jpg');
        background-size: 200%;
        border-left: solid 1px black;
        border-radius: 50%;
        box-shadow: 5px 0 20px 10px #040615 inset; 
        height: 300px;
        margin-left: 110px;
        position: absolute;
        width: 300px;
        z-index: 3;
    }
    .clouds {
        animation: rotate-day 50s linear infinite, spin-clouds 100s ease infinite;
        background-image: url('../../Resources/Images/2k_earth_clouds.jpg');
        background-size: 200%;
        border-radius: 50%;    
        box-shadow: 5px 0 20px 10px #040615 inset, -9px 0px 20px 10px #5e90f1 inset;
        height: 300px;
        margin-left: 100px;
        opacity: 0.45;
        position: absolute;
        width: 300px;
        z-index: 4;
    }
    .inner-shadow {
        background: transparent;
        border-radius: 50%;
        box-shadow: -5px 0 10px 1px #152b57 inset, 5px 0 10px 1px #040615 inset;
        height: 300px;
        margin-left: 0;
        position: absolute;
        width: 300px;
        z-index: 5;
    }
}

@media (max-width: 400px) {
    .planet-container {
        border-radius: 50%;
        box-shadow: 5px -3px 10px 3px #5e90f1;
        height: 200px;
        overflow: hidden;
        position: relative;
        width: 200px;
        z-index: 1;
    }
    .night {
        animation: rotate-night 80s linear infinite;
        background-image: url('../../Resources/Images/2k_earth_nightmap.jpg');
        background-size: 200%;
        height: 200px;
        position: absolute;
        width: 200px;
        z-index: 2;
    }
    .day {
        animation: rotate-day 80s linear infinite;
        background-image: url('../../Resources/Images/2k_earth_daymap.jpg');
        background-size: 200%;
        border-left: solid 1px black;
        border-radius: 50%;
        box-shadow: 5px 0 20px 10px #040615 inset; 
        height: 200px;
        margin-left: 110px;
        position: absolute;
        width: 200px;
        z-index: 3;
    }
    .clouds {
        animation: rotate-day 50s linear infinite, spin-clouds 100s ease infinite;
        background-image: url('../../Resources/Images/2k_earth_clouds.jpg');
        background-size: 200%;
        border-radius: 50%;    
        box-shadow: 5px 0 20px 10px #040615 inset, -9px 0px 20px 10px #5e90f1 inset;
        height: 200px;
        margin-left: 100px;
        opacity: 0.45;
        position: absolute;
        width: 200px;
        z-index: 4;
    }
    .inner-shadow {
        background: transparent;
        border-radius: 50%;
        box-shadow: -5px 0 10px 1px #152b57 inset, 5px 0 10px 1px #040615 inset;
        height: 200px;
        margin-left: 0;
        position: absolute;
        width: 200px;
        z-index: 5;
    }
}