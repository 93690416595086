@font-face {
    font-family: 'NunitoSans-Regular';
    src: url('../../../Resources/Fonts/NunitoSans_10pt-Regular.ttf') format('woff2');
}

@font-face {
    font-family: 'NunitoSans-Bold';
    src: url('../../../Resources/Fonts/NunitoSans_10pt-Bold.ttf') format('woff2');
}

@font-face {
    font-family: 'NunitoSans-Light';
    src: url('../../../Resources/Fonts/NunitoSans_10pt-Light.ttf') format('woff2');
}

.navbar-main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 75px;
    background-color: rgb(9, 8, 13);
    z-index: 10;
    /* Ensure it's on top of other content */
    transition: background-color 1s ease;
    /* Optional transition effect */
    display: flex;
    justify-content:flex-end;
    /* Align logo and links at opposite ends */
    align-items: center;


}

.demi-logo-holder{
    width: 75px;
    height: 75px;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
}

.the-img{
    width: 75px;
    height: 75px;
}

.menu-opened{
    background-color: black;
}

.navbar-main.scrolled {
    background-color: black;
    /* Adjust the color and opacity as needed */
}

.navbar-logo {
    width: 75px;
    /* Adjust the width as needed */
    height: 75px;
    /* Match the navbar height */
    display: flex;
    align-items: center;
}

.navbar-logo img {
    max-width: 100%;
    max-height: 100%;
}

.navbar-links {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.navbar-links p {
    color: white;
    margin-left: 60px;
    font-family: 'NunitoSans-Bold';
    font-size: 12px;
    cursor: pointer;
}

.navbar-link{
    font-family: 'NunitoSans-Bold';
    font-size: 12px;
}

.navbar-links p:hover {
    color: rgba(255, 241, 165,1);

}

.navbar-button {
    width: 145px;
    height: 40px;
    border-radius: 50px;
    border: 0px;
    background-color: rgba(255, 241, 165,1);
    color: black;
    font-family: 'NunitoSans-Light';
    font-size: 14px;
    cursor: pointer;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    margin-left: 60px;
}

.yello-margin{
    margin-left: 60px;

}

.navbar-button:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 6px 10px rgba(45, 68, 75, 0.3);
    transform: translateY(-5px);
}

.navbar-menu {
    display: none;
    /* Hide by default on larger screens */
    margin-right: 20px;
}

.menu-line {
    width: 35px;
    height: 2px;
    background-color: rgba(255, 241, 165,1);
    margin: 6px 0;
}

.open-menu {
    position: absolute;
    top: 75px;
    right: 0;
    background-color: black;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 0px;
    opacity: 0;
    transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    display: none;
}

.open-menu.openmenu {
    height: 200px;
    opacity: 1;
}

.open-menu p {
    margin: 12px 0;
    color: white;
    font-family: 'NunitoSans-Bold';
    font-size: 12px;
    cursor: pointer;
}

.open-menu p:hover {
    color: rgb(235, 225, 196);
}



.services-menu {
    position: relative;
}

.sub-menu {
    display: block;
    position: absolute;
    top: 100%;
    left: 0px;
    width: 150px;
    background-color: rgb(56, 75, 68);
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    justify-content: center;
    align-items: center;
    opacity: 0;
    height: 0px;
    transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;

}

.services-menu:hover .sub-menu {
    height: 100px; /* Adjust the value as needed */
    opacity: 1;
}

.sub-menu p {
    margin: 12px 0;
    color: white;
    cursor: pointer;
    font-family: 'NunitoSans-Light';
    justify-content: center;
    align-items: center;

}

.sub-menu p:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
}

.sub-menu-item {
    text-align: center;
    justify-content: center;
}

.openmenu-sub{
    width: 100%;
    height: 0px;
    opacity: 0;
    transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    background-color: rgb(76, 95, 88);
}

.openmenu-sub.openmenu2{
    height: 100px;
    opacity: 1;

   
}

.openmenu-services{
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */

}

@media (max-width: 1000px) {
    .navbar-links {
        display: none;
    }

    .navbar-menu {
        display: block;
        /* Display on smaller screens */
        cursor: pointer;
    }

    .open-menu {
        display: block;
    }
}